import { AppTheme } from './types/theme';
import { css } from 'styled-components';
import ViewIcon from 'view-icon.svg';
import NoViewIcon from 'no-view-icon.svg';

export default function (theme: AppTheme) {
    return css`
        .password-view {
            font-size: 6px;
            letter-spacing: 2px;
        }
        .password {
            position: relative;
        }
        .password-view-control {
            position: absolute;
            right: 33px;
            bottom: 34px;
            display: inline-block;
            width: 20px;
            height: 20px;
            border: none;
            background: url(${NoViewIcon}) 0 0 no-repeat;

            &--show {
                background: url(${ViewIcon}) 0 0 no-repeat;
            }
        }
    `;
}
