import { AppTheme } from '../../types/theme';
import { createGlobalStyle, css } from 'styled-components';
import { withTheme } from '@material-ui/core';

function createFlatpickrCss(theme: AppTheme) {
    const sassVars = theme.variables.sass as any;
    return css`
        .flatpickr-calendar {
            &.arrowTop,
            &.arrowBottom {
                padding: ${sassVars.datepickerContainerPadding} !important;
                border: ${sassVars.datepickerContainerBorder} !important;
                box-shadow: ${sassVars.datepickerBoxShadow} !important;

                &:before,
                &:after {
                    left: 50% !important; // выравнивание стрелки по центру календаря
                }

                &:before {
                    border-width: ${sassVars.datepickerArrowBeforeSize} !important;
                    margin: 0 -${sassVars.datepickerArrowBeforeSize} !important;
                }

                &:after {
                    border-width: ${sassVars.datepickerArrowAfterSize} !important;
                    margin: 0 -${sassVars.datepickerArrowAfterSize} !important;
                }

                .numInputWrapper:hover {
                    background-color: transparent !important;

                    .numInput {
                        background: ${sassVars.inputBackgroundColor} !important;

                        &:not(:focus) {
                            border-color: ${sassVars.inputHoverBorderColor} !important;
                        }
                    }
                }

                .flatpickr-monthDropdown-months,
                .numInput {
                    font-family: ${sassVars.inputFontFamily} !important;
                    font-weight: ${sassVars.inputFontWeight} !important;
                    font-size: ${sassVars.datepickerFontSize} !important;
                    line-height: ${sassVars.datepickerLineHeight} !important;
                    border: ${sassVars.inputBorderStyle} ${sassVars.backgroundMainColor} !important;
                    border-width: ${sassVars.datepickerInputBorderWidth} !important;

                    &:hover,
                    &:focus {
                        background: ${sassVars.inputBackgroundColor} !important;
                    }

                    &:hover {
                        border-color: ${sassVars.inputHoverBorderColor} !important;
                    }

                    &:focus,
                    &:focus:hover {
                        border-color: ${sassVars.inputFocusBorderColor} !important;
                    }
                }

                .flatpickr-months {
                    .flatpickr-prev-month,
                    .flatpickr-next-month {
                        position: relative !important;
                        padding: 0 !important;
                        width: ${sassVars.datepickerBaseSize} !important;
                        height: ${sassVars.datepickerBaseSize} !important;

                        .flatpickr-icon:before {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                            font-family: ${theme.variables.icons.fontFamily};
                            content: ${theme.variables.icons.arrowIcon.content};
                            font-size: ${theme.variables.icons.arrowIcon.fontSize}px;
                            font-style: normal;
                            transform: rotate(90deg);
                        }
                    }

                    .flatpickr-next-month .flatpickr-next-icon:before {
                        transform: rotate(-90deg);
                    }

                    .flatpickr-month,
                    .flatpickr-current-month {
                        height: ${sassVars.datepickerBaseSize};
                    }

                    .flatpickr-current-month {
                        width: 100% !important;
                        left: 0 !important;
                        padding: 0 !important;
                        font-size: ${sassVars.datepickerFontSize} !important;
                        line-height: ${sassVars.datepickerLineHeight} !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .flatpickr-monthDropdown-months {
                            margin: 0 ${sassVars.datepickerBaseMargin} 0 0;
                            padding: 0 ${sassVars.datepickerInputPaddingHorizMin} 0 ${sassVars.monthDropdownPaddingLeft};
                        }

                        .numInputWrapper {
                            width: ${sassVars.datepickerInputWidth};
                        }

                        .numInput {
                            padding: 0 ${sassVars.datepickerInputPaddingHorizMin} 0
                                ${sassVars.datepickerInputPaddingHoriz};
                        }
                    }
                }

                .flatpickr-innerContainer {
                    .flatpickr-rContainer {
                        position: relative;
                        width: 100%;
                    }

                    .flatpickr-weekdays {
                        height: ${sassVars.datepickerBaseSize};
                        margin: ${sassVars.datepickerBaseMargin} 0;
                    }

                    .flatpickr-weekday {
                        font-family: ${sassVars.datepickerWeekdayFontFamily};
                        font-size: ${sassVars.datepickerWeekdayFontSize};
                        font-weight: ${sassVars.datepickerWeekdayFontWeight};
                        line-height: ${sassVars.datepickerWeekdayLineHeight};
                        text-transform: ${sassVars.datepickerWeekdayTextTransform};
                        letter-spacing: ${sassVars.datepickerWeekdayLetterSpacing};
                        color: ${sassVars.datepickerWeekdayColor};
                        width: ${sassVars.datepickerBaseSize};
                        margin-right: ${sassVars.datepickerBaseMargin};
                    }

                    .flatpickr-days,
                    .dayContainer {
                        width: 100%;
                        min-width: 100%;
                        max-width: 100%;
                    }

                    .flatpickr-day {
                        max-width: ${sassVars.datepickerBaseSize};
                        height: ${sassVars.datepickerBaseSize};
                        line-height: ${sassVars.datepickerBaseSize};
                        margin-right: ${sassVars.datepickerBaseMargin};
                        margin-bottom: ${sassVars.datepickerSmallMargin};
                        border: none;
                        border-radius: ${sassVars.datepickerDayBorderRadius};
                        color: ${theme.palette.secondary.contrastText};

                        &:hover {
                            background-color: ${theme.variables.palette.hoverInLists};
                        }

                        &.prevMonthDay,
                        &.nextMonthDay {
                            color: rgba(57, 57, 57, 0.3);
                        }

                        &.today {
                            color: ${sassVars.datepickerDayTodayColor};
                        }

                        &.selected {
                            background: ${theme.palette.primary.main};
                            color: ${theme.palette.primary.contrastText};

                            &:hover {
                                color: ${theme.palette.primary.contrastText};
                            }
                        }
                    }
                }
            }

            &.arrowTop {
                margin-top: 9px !important;

                &:before {
                    border-bottom-color: ${sassVars.datepickerContainerBorderColor} !important;
                }
            }

            &.arrowBottom {
                margin-top: -44px !important;

                &:before {
                    border-top-color: ${sassVars.datepickerContainerBorderColor} !important;
                }
            }

            &.showTimeInput.hasTime .flatpickr-time {
                border-top: ${sassVars.datepickerTimeBorderTop} !important;
                padding-top: ${sassVars.datepickerTimePaddingTop} !important;
                height: ${sassVars.datepickerTimeHeight} !important;
                display: flex;
                align-items: center;
                justify-content: center;

                .numInputWrapper,
                .flatpickr-time-separator {
                    height: ${sassVars.datepickerLineHeight};
                    line-height: ${sassVars.datepickerLineHeight};
                }

                .numInputWrapper {
                    flex: 0;
                }

                .flatpickr-hour,
                .flatpickr-minute {
                    width: ${sassVars.datepickerInputWidth} !important;
                }

                .flatpickr-hour {
                    text-align: right;
                    padding-right: ${sassVars.datepickerInputHourPadding};
                }

                .flatpickr-minute {
                    text-align: left;
                    padding-left: ${sassVars.datepickerInputPaddingHoriz};
                    margin-left: ${sassVars.datepickerInputMinuteMargin};
                }
            }
        }
    `;
}

const GlobalStyledFlatpickr = createGlobalStyle<{ theme: AppTheme }>`
    ${({ theme }) => createFlatpickrCss(theme)}
`;

export default withTheme(GlobalStyledFlatpickr);
