import React from 'react';
import { WebForm } from '@platform/formio/WebForm';
import { FormApi } from '../api/FormApi';
import { FormModel, FullSubmission } from '../models/FormModel';
import { Box, CircularProgress, Grid, Paper, Tab, Tabs, withTheme } from '@material-ui/core';
import { action, IReactionDisposer, observable, reaction } from 'mobx';
import { FormioFormApi } from '../api/FormioFormApi';
import FormComponentApi from '../api/FormComponentApi';
import FormioForm from './FormioForm';
import styled from 'styled-components';
import { IntlStore } from '../types/intl';
import { observer } from 'mobx-react';

const LoaderBox = withTheme(
    styled(Box)`
        text-align: center;
    `,
);

export type MultiLangFormEditProps = {
    intlStore: IntlStore;
    form: FormModel;
    onFormReady?: (instance: FormApi) => void;
    onFormChange?: (instance: WebForm) => void;
};

@observer
class MultiLangFormEdit extends React.Component<MultiLangFormEditProps, {}> implements FormComponentApi {
    @observable currentLang: string;
    @observable formApi?: FormioFormApi;
    @observable disposer: IReactionDisposer;

    constructor(props: MultiLangFormEditProps) {
        super(props);
        this.currentLang = this.props.intlStore.locale;
        // без этого никак, пробовала разными способами переписать - начинает проявляться баг, исправленный в рамках https://totsys.atlassian.net/browse/MEXP-305
        this.disposer = reaction(
            () => this.props.intlStore.locale,
            () => this.saveSubmission(this.props.form),
        );
    }

    @action.bound
    setCurrentLang(newLang: string): void {
        this.currentLang = newLang;
    }

    getLang = (): string => {
        return this.currentLang;
    };

    getReadOnly = (): boolean => {
        return false;
    };

    getSubmission = (): FullSubmission => {
        const { form } = this.props;
        this.saveSubmission(form);
        return form.getFullSubmission();
    };

    saveSubmission = (form: FormModel): void => {
        if (this.formApi) {
            form.setLangSubmission(this.getLang(), this.formApi.getSubmission());
        }
    };

    setStartSubmission = (): void => {
        const { form } = this.props;
        form.setStartSubmission(this.getLang());
    }

    setSubmissionFromStartSubmission = (): void => {
        const { form } = this.props;
        form.setSubmissionFromStartSubmission();
    }

    onFormReady = (form: FormioFormApi): void => {
        const { onFormReady } = this.props;
        this.formApi = form;
        const api = new FormApi(this, form);
        onFormReady && onFormReady(api as FormApi);
    };

    handleChange = (event: React.ChangeEvent<{}>, value: string): void => {
        const { form } = this.props;
        const prevLang = this.currentLang;
        this.setCurrentLang(value);

        const prevLangSubmission = this.formApi?.getSubmission();
        if (prevLangSubmission) {
            form.setLangSubmission(prevLang, prevLangSubmission);
        }
    };

    render(): JSX.Element {
        const { form, onFormChange, intlStore } = this.props;
        const { locales } = intlStore;
        const formioProps = {
            form: form.form,
            submission: form.getLangSubmission(this.currentLang) || form.emptySubmission(),
            i18n: form.i18n,
            locale: this.currentLang,
            onFormReady: this.onFormReady,
            onFormChange: onFormChange,
            readOnly: false,
        };

        return (
            <Grid container spacing={10} direction="column">
                <Grid item style={{ paddingBottom: '1px' }}>
                    <Paper square>
                        <Tabs
                            value={this.currentLang}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={this.handleChange}
                        >
                            {locales.map((locale) => {
                                return <Tab key={locale} value={locale} label={locale} />;
                            })}
                        </Tabs>
                    </Paper>
                </Grid>
                <Grid item style={{ maxWidth: '100%', paddingTop: 0 }}>
                    {form.loaded ? (
                        <FormioForm {...formioProps} />
                    ) : (
                        <LoaderBox>
                            <CircularProgress />
                        </LoaderBox>
                    )}
                </Grid>
            </Grid>
        );
    }

    componentWillUnmount(): void {
        this.disposer();
    }
}

export default MultiLangFormEdit;
