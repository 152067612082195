import { AppTheme } from '../../types/theme';
import { css } from 'styled-components';
import formLabel from '../mixins/formLabel';

export default function (theme: AppTheme) {
    const sassVars = theme.variables.sass as any;
    return css`
        .formio-component-datagrid {
            .datagrid-table {
                border: ${sassVars.formioDatagridBorder};
                margin: ${sassVars.formioDatagridMargin};
                
                tr,
                td,
                th {
                    border-width: 0;
                }
                
                td,
                th {
                    &:first-child {
                        padding-left: ${sassVars.formioDatagridPaddingHorizontal} !important;
                    }
                    
                    &:nth-last-child(2) {
                        padding-right: 0 !important;
                    }
                }
                
                th {
                    ${({ theme }) => formLabel(theme)}
                    padding: ${sassVars.formioDatagridThPaddingTop} ${sassVars.formioDatagridCellPadding} ${
        sassVars.formioDatagridCellPadding
    } !important;
                }
                
                td {
                    padding: ${sassVars.formioDatagridCellPadding} !important;
                
                    .control-label--hidden.field-required {
                        display: none;
                    }
                }
                
                tr:first-child td:not(.formio-remove-column) {
                    padding-top: 0 !important;
                }
                
                tr:not(:first-child) .formio-remove-column {
                    padding-top: ${sassVars.formioDatagridRemoveColPaddingTop} !important;
                }
                
                tfoot td {
                    padding-bottom: ${sassVars.formioDatagridTfootPaddingBottom} !important;
                }
                
                .form-group {
                    margin-top: 0;
                    margin-bottom: 0;
                }
                
                .formio-remove-column {
                    padding: ${sassVars.formioDatagridRemoveColPaddingVert} ${
        sassVars.formioDatagridRemoveColPaddingRight
    } ${sassVars.formioDatagridRemoveColPaddingVert} ${sassVars.formioDatagridRemoveColPaddingLeft} !important;
                }
                
                .formio-button-add-row {
                    float: right;
                    margin-right: ${sassVars.formioDatagridBtnAddRowMarginRight};
                    margin-top: ${sassVars.formioTextfieldMultipleButtonAddRowMarginTop};
                    box-shadow: none;
                    
                    i {
                        display: none;
                    }
                }
            }
        }
    `;
}
