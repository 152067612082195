import Formiojs from '@platform/formio/Form';
import { Submission, WebForm } from '@platform/formio/WebForm';
import merge from 'deepmerge';
import i18next from 'i18next';
import React from 'react';
import { FormioFormApi } from '../api/FormioFormApi';
import { defaultFormsLocale } from '../constants/intl';
import { StyledForm } from '../styled/Form.styled';
import GlobalStyledFlatpickr from '../styled/global-components/GlobalStyledFlatpickr';
import GlobalStyledFormioModal from '../styled/global-components/GlobalStyledFormioModal';
import defaultI18n from './i18n.json';

export type FormioFormProps = {
    form: object;
    submission?: Submission;
    i18n?: object;
    locale: string;
    onFormReady?: (instance: FormioFormApi) => void;
    onFormChange?: (instance: WebForm) => void;
    readOnly?: boolean;
    setFormIsChanged?: (formIsChanged: boolean) => void;
    className?: string;
};

const defaultOptions = { language: defaultFormsLocale, disableAlerts: true, noAlerts: true };

class FormioForm extends React.Component<FormioFormProps, {}> {
    formRef = React.createRef<HTMLDivElement>();
    instance?: WebForm;
    i18nextInstance?: i18next.i18n;

    componentDidUpdate(prevProps: Readonly<FormioFormProps>): void {
        const { onFormReady, readOnly, locale: newLocale, form: newForm, submission: newSubmission } = this.props;
        const { locale: prevLocale, form: prevForm, submission: prevSubmission } = prevProps;

        if (prevLocale !== newLocale) {
            this.changeFormLanguage(newLocale);
        }

        if (newForm !== prevForm && this.instance) {
            this.instance.setForm(newForm).then((form) => {
                if ((form as any).components) {
                    const formApi = new FormioFormApi(this.instance as WebForm, readOnly);
                    onFormReady && onFormReady(formApi);
                }
            });
        }

        if (newSubmission !== prevSubmission && this.instance) {
            this.instance.setSubmission(newSubmission || { data: {}, meta: {} });
        }
    }

    componentDidMount(): void {
        const {
            form,
            submission,
            i18n: formI18n,
            onFormReady,
            readOnly,
            locale: language,
            setFormIsChanged,
            onFormChange,
        } = this.props;
        const i18n = merge(defaultI18n, { resources: formI18n || {} });
        this.i18nextInstance = i18next.createInstance(i18n as i18next.InitOptions, (err: any) => {
            if (err) {
                console.error("can't create i18next instance", err);
            }
        });
        const options = {
            ...defaultOptions,
            i18n,
            readOnly,
            viewAsHtml: readOnly,
            language,
            i18nReady: true,
            i18next: this.i18nextInstance,
        };
        new Formiojs(this.formRef.current, form, options).render().then((instance: WebForm) => {
            this.instance = instance;
            this.changeFormLanguage(language);
            if (submission) {
                instance.setSubmission(submission);
            }
            if (form && (form as any).components) {
                const formApi = new FormioFormApi(instance, readOnly);
                onFormReady && onFormReady(formApi);
            }

            if (setFormIsChanged) {
                instance.on('change', (event: any) => {
                    if (event.changed?.flags?.modified || event.changed?.component.type === 'datetime') {
                        setFormIsChanged(true);
                    }
                });
            }

            if (onFormChange) {
                instance.on('change', (event: any) => {
                    onFormChange(event);
                });
            }
        });
    }

    componentWillUnmount = (): void => {
        if (this.instance !== undefined) {
            this.instance.destroy();
        }
        this.props.setFormIsChanged && this.props.setFormIsChanged(false);
    };

    changeFormLanguage(lang: string): void {
        this.i18nextInstance?.changeLanguage(lang, (err) => {
            if (err) {
                console.error("can't change form language", err);
            }
            if (this.instance) {
                this.instance.language = lang;
            }
        });
    }

    render(): JSX.Element {
        const className = this.props.className;
        return (
            <StyledForm>
                <GlobalStyledFlatpickr />
                <GlobalStyledFormioModal />
                <div ref={this.formRef} className={className} />
            </StyledForm>
        );
    }
}

export default FormioForm;
