import { AppTheme } from '../../types/theme';
import { css } from 'styled-components';
import panelTitle from '../mixins/panelTitle';
import panelSubTitle from '../mixins/panelSubTitle';
import campaignRequestPrintForms from './campaignRequestPrintForms';

export default function (theme: AppTheme) {
    return css`
        .panel {
            border-radius: 0;
            box-shadow: none;
            margin-bottom: 30px;
        }

        .panel-default {
            border: none;
        }

        .panel-heading {
            border-bottom: none;
            padding: 10px 20px;
        }

        ${() => campaignRequestPrintForms()}

        .panel-default {
            margin-bottom: 1px;
            margin-top: 1px;

            &:last-child {
                margin-bottom: 0;
            }

            &:first-child {
                margin-top: 0;
            }

            .panel-title {
                display: flex;
                align-items: center;
                min-height: 60px;

                .formio-collapse-icon {
                    width: ${theme.variables.button.sizes.medium}px;
                    height: ${theme.variables.button.sizes.medium}px;
                    position: absolute;
                    right: 23px;
                    top: auto;
                    border: none;
                    border-radius: ${theme.variables.button.sizes.medium / 2}px;
                    color: ${theme.variables.palette.iconButton.color};
                    margin: 0;

                    &:before {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        font-family: ${theme.variables.icons.fontFamily};
                        content: ${theme.variables.icons.arrowIcon.content};
                        font-size: ${theme.variables.icons.arrowIcon.fontSize}px;
                        transform: rotate(180deg);
                    }

                    &.glyphicon-plus:before {
                        transform: none;
                    }

                    &:nth-child(2) {
                        top: calc(50% - ${theme.variables.button.sizes.medium / 2}px);
                    }
                }

                .panel-index {
                    display: inline-block;
                    counter-increment: panelCounter;
                    padding: 29px 0;
                    width: 60px;
                    min-width: 60px;
                    text-align: center;
                    border-right: 1px solid ${theme.variables.sass.backgroundMainColor};
                    margin-right: 23px;

                    &--default::before {
                        content: counters(panelCounter, '.');
                    }
                }

                .panel-title-text:first-child {
                    padding-left: 24px;
                    margin: auto 0;
                }
            }

            > .panel-heading {
                background-color: transparent;
                border-bottom: 1px solid ${theme.variables.palette.hoverInLists};
                padding: 0;
                border-radius: 0;

                .panel-title {
                    ${panelTitle(theme)};
                    position: relative;
                }

                &:hover .panel-title {
                    cursor: pointer;

                    .formio-collapse-icon {
                        color: ${theme.variables.palette.iconButton.hoverColor};
                        background: ${theme.variables.palette.iconButton.hoverBackgroundColor};
                    }
                }
            }

            .panel-body {
                padding: ${theme.variables.sass.panelBodyOffsetVertical} 0;
            }

            > .panel-body {
                counter-reset: panelCounter;

                .panel-title {
                    ${panelSubTitle(theme)};

                    .panel-index {
                        padding: 4px 0;
                        border-right: none;
                        background: ${theme.variables.palette.panel.markerBackgroundColor};
                        color: ${theme.variables.palette.panel.markerColor};
                    }

                    .panel-title-text:first-child {
                        padding-left: 83px;
                    }
                }

                .panel-body {
                    border-bottom: 1px solid ${theme.variables.sass.borderColor};
                    padding-left: 83px;
                    padding-right: 83px;

                    .panel-body {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    .panel-title-text:first-child {
                        padding-left: 0;
                    }
                }

                .panel-default {
                    margin-bottom: 23px;

                    &:last-child {
                        margin-bottom: 0;

                        > .panel-body {
                            border-bottom: none;
                            padding-bottom: 0;
                        }
                    }

                    > .panel-heading {
                        border-bottom: none;
                    }
                }

                .panel-heading {
                    background-color: transparent;
                }
            }
        }
    `;
}
