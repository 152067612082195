import { AppTheme } from '../../types/theme';
import { css } from 'styled-components';

export default function (theme: AppTheme) {
    return css`
        .formio-component {
            &-table,
            &-datagrid,
            &-editgrid {
                position: relative;
                display: flex;
                flex-direction: column;
                overflow-y: hidden;
                overflow-x: auto;
            }

            &--fullscreen {
                position: fixed !important;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: ${theme.palette.background.paper};
                z-index: ${theme.zIndex.modal};

                padding: 0.5rem;
                padding-left: 1rem !important;
                padding-right: 1rem !important;
                margin: 0 !important;
            }

            &--fullscreen tbody {
                border-right: ${theme.variables.sass.fileListItemBorderBottomWidth}
                    ${theme.variables.sass.fileListItemBorderBottomStyle}
                    ${theme.variables.sass.fileListItemBorderBottomColor};
                border-left: ${theme.variables.sass.fileListItemBorderBottomWidth}
                    ${theme.variables.sass.fileListItemBorderBottomStyle}
                    ${theme.variables.sass.fileListItemBorderBottomColor};
            }

            .fullscreen-btn {
                color: ${theme.variables.palette.iconButton.color};
                align-self: flex-end;

                &:hover {
                    background: ${({ theme }) => theme.variables.palette.panel.markerBackgroundColor};
                    color: ${({ theme }) => theme.variables.palette.panel.markerColor};
                }

                svg {
                    outline: none;
                }
            }
        }
    `;
}
