import { AppTheme } from '../../types/theme';
import { css } from 'styled-components';

export default function (theme: AppTheme) {
    return css`
        // перебивает стили из bootstrap
        .formio-component-columns.row {
            margin-right: 0;
            margin-left: 0;
        }
    `;
}
