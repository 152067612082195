import { AppTheme } from '../../types/theme';
import textEllipsis from '../mixins/textEllipsis';
import { css } from 'styled-components';

export default function (theme: AppTheme) {
    const sassVars = theme.variables.sass as any;
    const formControl = theme.variables.formio.formControl;
    return css`
        .form-control,
        .choices__inner {
            display: block;
            box-shadow: ${formControl.border(theme.variables.palette.mainMiddleLight, 1)};
            border-radius: ${formControl.borderRadius}px;
            padding: ${formControl.padding};
            outline: none;
            border: none;
            margin: 0;
            min-height: ${formControl.minHeight}px;
            font-family: ${sassVars.inputFontFamily};
            font-weight: ${formControl.fontWeight};
            font-size: ${formControl.fontSize}px;
            line-height: ${formControl.lineHeight}px;

            transition: ${sassVars.inputTransition};

            &:hover {
                box-shadow: ${formControl.border(theme.variables.palette.accentContrast, 1)};

                // иконка (или текст) возле поля
                + .input-group-addon {
                    border-color: ${sassVars.inputHoverBorderColor};
                    color: ${sassVars.inputHoverIconColor};
                }
            }

            &:focus {
                box-shadow: ${formControl.border(theme.variables.palette.accentContrast, 2)};

                + .input-group-addon {
                    border-color: ${sassVars.inputFocusBorderColor};
                    color: ${sassVars.inputFocusIconColor};
                }
            }

            &:not(textarea):not(div) {
                ${() => textEllipsis()}
            }

            &:disabled,
            &[disabled],
            &.disabled {
                background: ${sassVars.inputDisabledBackgroundColor};
                color: ${sassVars.inputDisabledColor};
                border-color: ${sassVars.inputDisabledBorderColor};
                cursor: not-allowed;

                &::placeholder {
                    color: ${sassVars.inputDisabledColor};
                }
            }
        }
        .has-error > .form-control.is-invalid,
        .has-error > .choices > .form-control,
        .has-error > .input-group > .form-control,
        .has-error > .choices__inner.is-invalid {
            box-shadow: ${formControl.border(theme.variables.palette.red, 1)};
            border: none;
        }
        .has-error > .form-control.is-invalid:hover,
        .has-error > .choices > .form-control:hover,
        .has-error > .choices__inner.is-invalid:hover,
        .has-error > .input-group > .form-control:hover,
        .has-error > .form-control.is-invalid:focus,
        .has-error > .input-group > .form-control:focus,
        .has-error > .choices > .form-control:focus,
        .has-error > .choices__inner.is-invalid:focus {
            box-shadow: ${formControl.border(theme.variables.palette.red, 2)};
            border: none;
        }

        .form-group {
            display: flex;
            flex-direction: column;
            width: 100%;

            & .control-label {
                order: 0;
            }
            & .form-control,
            .choices__inner {
                order: 1;
            }
            & .help-block {
                order: 2;
            }

            & .help-block,
            .choices__inner {
                order: 4;
            }
            & .formio-errors {
                order: 3;
            }
        }

        .form-control-md {
            padding: ${sassVars.inputMdPadding};
            min-height: ${sassVars.inputMdHeight};
        }

        .form-control::placeholder {
            color: ${sassVars.inputPlaceholderColor};
        }

        textarea.form-control {
            resize: vertical;
        }

        // когда справа от поля есть иконка, радиус нужен только левому верхнему уголку
        .input-group .form-control:not(:first-child):not(:last-child) {
            border-radius: ${sassVars.inputBorderRadiusSize} 0 0 0;
        }

        .input-group .form-control {
            z-index: 0; // перебиваем стили бутстрапа, т.к. инпут перекрывал другие элементы
        }
    `;
}
