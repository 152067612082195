import { AppTheme } from '../../types/theme';
import { css } from 'styled-components';

export default function (theme: AppTheme) {
    const sassVars = theme.variables.sass as any;
    return css`
        .formio-component-textfield.formio-component-multiple .formio-button-remove-row,
        .formio-component-editgrid .removeRow,
        .formio-component-editgrid .editRow,
        .formio-component-datagrid .formio-button-remove-row {
            padding: ${sassVars.formioSubgroupRowActionBtnPadding} !important;
            line-height: ${sassVars.formioSubgroupRowActionBtnLineHeight};
            letter-spacing: ${sassVars.formioSubgroupRowActionBtnLetterSpacing};

            i {
                font-family: ${sassVars.formioSubgroupRowActionBtnIconFontFamily};
                font-size: ${sassVars.formioSubgroupRowActionBtnIconFontSize};
                line-height: ${sassVars.formioSubgroupRowActionBtnIconLineHeight};
                height: ${sassVars.formioSubgroupRowActionBtnIconFontSize};
            }
        }

        .formio-component-textfield.formio-component-multiple .formio-button-remove-row,
        .formio-component-editgrid .removeRow,
        .formio-component-datagrid .formio-button-remove-row {
            min-width: auto;
            background: ${sassVars.formioSubgroupRowRemoveBtnBackgroundColor};
            color: ${sassVars.formioSubgroupRowRemoveBtnColor};
            border: ${sassVars.formioSubgroupRowRemoveBtnBorder};

            &:hover,
            &:focus,
            &:hover:focus {
                background: ${sassVars.formioSubgroupRowRemoveBtnHoverBackgroundColor};
                color: ${sassVars.formioSubgroupRowRemoveBtnHoverColor};
                border: ${sassVars.formioSubgroupRowRemoveBtnHoverBorder};
            }

            i:before {
                content: '${sassVars.formioSubgroupRowRemoveBtnIconContent}';
            }
        }

        .formio-component-editgrid .editRow {
            min-width: auto;
            background: ${sassVars.formioSubgroupRowEditBtnBackgroundColor};
            color: ${sassVars.formioSubgroupRowEditBtnColor};
            border: ${sassVars.formioSubgroupRowEditBtnBorder};

            &:hover,
            &:focus,
            &:hover:focus {
                background: ${sassVars.formioSubgroupRowEditBtnHoverBackgroundColor};
                color: ${sassVars.formioSubgroupRowEditBtnHoverColor};
                border: ${sassVars.formioSubgroupRowEditBtnHoverBorder};
            }

            i:before {
                content: '${sassVars.formioSubgroupRowEditBtnIconContent}';
            }
        }
    `;
}
