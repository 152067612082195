import { AppTheme } from '../../types/theme';
import { css } from 'styled-components';

export default function (theme: AppTheme) {
    return css`
        color: ${theme.palette.secondary.contrastText};
        font-family: 'Open Sans', sans-serif;
        font-size: 15px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0.3px;
    `;
}
