import { AppTheme } from '../../types/theme';
import { css } from 'styled-components';

export default function (theme: AppTheme) {
    const sassVars = theme.variables.sass as any;
    return css`
        .formio-disabled-input {
            .ql-toolbar {
                display: none;
            }
        }

        .formio-component-textarea {
            &--disabled {
                img {
                    max-width: 100%;
                }

                border-top: ${sassVars.textareaDisabledBorder} !important;
                padding: ${theme.spacing(3)}px ${theme.spacing(4)}px;
                white-space: pre-wrap;
                word-wrap: break-word;

                .ql-editor {
                    padding: 0;
                }

                p {
                    margin-bottom: 0;
                }
            }

            .quill-source-code {
                width: 100%;
                margin: 0;
                box-sizing: border-box;
                outline: 0;
                padding: 20px;
                position: absolute;
                top: 0;
                bottom: 0;
                border: none;
                display: none;
            }
        }
    `;
}
