import { AppTheme } from '../../types/theme';
import { css } from 'styled-components';

export default function (theme: AppTheme) {
    const mainFontAttrs = theme.variables.fonts.mainFontAttrs;
    return css`
        .formio-component-expertiseCriterion {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .formio-component-expertiseForm,
        .formio-component-expertiseCriterion {
            .score {
                position: absolute;
                right: 24px;
            }

            .score-title {
                font-size: ${mainFontAttrs.fontSize};
                font-weight: ${mainFontAttrs.fontWeight};
                line-height: ${mainFontAttrs.lineHeight};
                letter-spacing: ${mainFontAttrs.letterSpacing};
            }
        }

        .formio-component-expertiseForm {
            > .panel-heading > .panel-title {
                &:before {
                    content: '';
                    width: 0;
                    min-width: 0;
                }

                .panel-title-text {
                    margin-right: 154px;
                    padding: 15px 0;
                    overflow: visible !important;
                    white-space: normal !important;
                }

                .score {
                    top: 9px;
                }

                .score-value {
                    font-size: 24px;
                    line-height: 1.5;
                    letter-spacing: normal;
                }

                .hide-report-title {
                    overflow: hidden !important;
                    white-space: nowrap !important;
                }
            }
        }

        .formio-component-expertiseForm {
            > .panel-heading {
                height: auto !important;
            }
        }

        .formio-component-expertiseCriterion {
            > .panel-heading > .panel-title {
                .panel-title-text {
                    margin-right: 168px;
                }

                .score {
                    color: ${theme.palette.primary.main};
                    right: 68px;
                }

                .score-value {
                    font-size: 18px;
                    line-height: 1.56;
                    letter-spacing: 0.36px;
                }
            }

            > .panel-body {
                border-bottom: none !important;
                padding-left: 0px !important;
                padding-right: 0px !important;

                dl {
                    padding-left: 83px !important;
                    padding-right: 83px !important;
                }
            }
            
            table .has-feedback {
                padding-left: 0;
                padding-right: 0;
            }

            .has-feedback {
                padding-left: 83px;
                padding-right: 68px;
            }
        }
    `;
}
