import { css } from 'styled-components';

export default function () {
    return css`
        &.print-form
            > div {
                > .panel-default {
                    > .panel-body {
                        > dl {
                            padding-left: 83px;
                            padding-right: 83px;
                        }
                    }
                }
                > dl {
                    padding-left: 83px;
                    padding-right: 83px;
                }
            }
        }
    `;
}
