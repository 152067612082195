import { AppTheme } from '../../types/theme';
import { css } from 'styled-components';
import buttonSmall from '../mixins/buttonSmall';

export default function (theme: AppTheme) {
    const sassVars = theme.variables.sass as any;
    return css`
        .formio-component-editgrid {
            .editgrid-listgroup {
                border: ${sassVars.formioEditgridBorder};
                padding: ${sassVars.formioEditgridPadding};
                margin: ${sassVars.formioEditgridMargin};
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                overflow: auto;

                .list-group-item {
                    padding: ${sassVars.formioEditgridItemPadding};
                    margin: ${sassVars.formioEditgridItemMargin};
                    border-width: ${sassVars.formioEditgridItemBorderWidth};
                    font-size: ${sassVars.formioEditgridItemFontSize};
                    line-height: ${sassVars.formioEditgridItemLineHeight};
                    letter-spacing: ${sassVars.formioEditgridItemLetterSpacing};
                    color: ${sassVars.formioEditgridItemColor};
                    border-top: 1px solid rgba(102, 102, 102, 0.4);
                    border-bottom: none;
                    min-width: 100%;

                    &:first-child {
                        border-top-width: ${sassVars.formioEditgridItemLastChildBorderBottomWidth};
                        padding-top: 0;
                    }

                    &:last-child {
                        border-bottom-width: ${sassVars.formioEditgridItemLastChildBorderBottomWidth};
                        padding-bottom: ${sassVars.formioEditgridItemLastChildPaddingBottom};

                        .editgrid-actions {
                            margin-bottom: -${sassVars.formioEditgridActionsBtnMarginBottom};
                        }
                    }

                    .editRow,
                    .removeRow {
                        margin-right: ${sassVars.formioEditgridBtnRowActionMarginRight};
                    }
                    
                    .file-item-view {
                        width: auto !important; 
                    }
                }

                .formio-edit-grid-header {
                    font-family: ${sassVars.formioEditgridHeaderFontFamily};
                    font-weight: ${sassVars.formioEditgridHeaderFontWeight};
                    font-size: ${sassVars.formioEditgridHeaderFontSize};
                    line-height: ${sassVars.formioEditgridHeaderLineHeight};
                    text-transform: ${sassVars.formioEditgridHeaderTextTransform};
                    letter-spacing: ${sassVars.formioEditgridHeaderLetterSpacing};
                    color: ${sassVars.formioEditgridHeaderColor};
                    padding-top: ${sassVars.formioEditgridHeaderPaddingTop};
                }

                .form-group {
                    margin-top: ${sassVars.formioEditgridFormGroupMarginTop};
                    margin-bottom: ${sassVars.formioEditgridFormGroupMarginBottom};
                }

                & .row {
                    display: flex;
                }

                & .col-sm-2 {
                    width: 200px;
                    flex-grow: 1;
                    position: static;
                    word-wrap: break-word;
                }
            }

            .editgrid-actions {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-wrap: wrap;

                .btn {
                    margin-bottom: ${sassVars.formioEditgridActionsBtnMarginBottom};
                }

                .btn-danger {
                    ${buttonSmall(theme)}
                    min-width: ${sassVars.formioBtnWidth};
                    order: 0;
                    background-color: ${theme.palette.error.contrastText};
                    color: ${theme.palette.error.main};
                    border-color: ${theme.palette.error.contrastText};

                    &:hover {
                        background-color: ${theme.palette.error.main};
                        border-color: ${theme.palette.error.main};
                        color: ${theme.palette.error.contrastText};
                    }
                }

                .btn-primary {
                    order: 1;
                    margin-left: ${sassVars.formioEditgridBtnRowActionMarginRight};
                }
            }

            .editgrid-add {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                border: ${sassVars.formioEditgridBorder};
                border-top: none;

                .btn {
                    margin-top: ${sassVars.formioEditgridBtnAddRowMarginVertical};
                    margin-right: ${sassVars.formioEditgridBtnAddRowMarginRight};
                    margin-bottom: ${sassVars.formioEditgridBtnAddRowMarginVertical};

                    .fa-plus {
                        display: none;
                    }
                }
            }
        }
    `;
}
