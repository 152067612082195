import { WebForm } from '@platform/formio/WebForm';
import { FullSubmission } from '../models/FormModel';
import { FormioFormApi } from './FormioFormApi';
import FormComponentApi from './FormComponentApi';

export class FormApi {
    private formComponent: FormComponentApi;
    private formioFormApi: FormioFormApi;

    constructor(formComponent: FormComponentApi, formioFormApi: FormioFormApi) {
        this.formComponent = formComponent;
        this.formioFormApi = formioFormApi;
    }

    readOnly = (): boolean => {
        return this.formComponent.getReadOnly();
    };

    form = (): WebForm => {
        return this.formioFormApi.form;
    };

    getSubmission = (): FullSubmission => {
        const defaultSubmission = {
            ru: {
                data: {},
                meta: {},
            },
        };
        const submission = this.formComponent.getSubmission();
        return Object.keys(submission).length ? submission : defaultSubmission;
    };

    setStartSubmission(): void {
        this.formComponent.setStartSubmission();
    }

    setSubmissionFromStartSubmission(): void {
        this.formComponent.setSubmissionFromStartSubmission();
    }

    getExpertiseInfo = (): object | undefined => {
        return this.formioFormApi.getExpertiseInfo();
    };

    validate = (): boolean => {
        return this.formioFormApi.validate();
    };

    submit = (validation: boolean): Promise<void> => {
        return this.formioFormApi.submit(validation);
    };

    destroy = (): void => {
        this.formioFormApi.destroy();
    };
}
