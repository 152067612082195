import { AppTheme } from '../../types/theme';
import { css } from 'styled-components';

export default function (theme: AppTheme) {
    const sassVars = theme.variables.sass as any;
    const formioDateTime = theme.variables.formio.formioDateTime;
    return css`
        .formio-component-datetime {
            & .form-control {
                border-radius: ${formioDateTime.formControl.borderRadius};
            }

            .input-group {
                width: 100%;
            }

            .input-group-addon {
                padding: 0;
                position: absolute;
                box-sizing: border-box;
                background-color: transparent;
                border: none;
                width: ${formioDateTime.inputGroupAddon.width};
                height: ${formioDateTime.inputGroupAddon.height};
                display: flex;
                justify-content: center;
                align-items: center;

                top: ${formioDateTime.inputGroupAddon.top};
                right: ${formioDateTime.inputGroupAddon.right};
            }

            &.formio-disabled-input {
                .form-control + .input-group-addon {
                    background-color: ${sassVars.datepickerDisabledBackgroundColor};
                    border-color: ${sassVars.datepickerDisabledBorderColor};
                    cursor: not-allowed !important;
                }
            }
        }
    `;
}
