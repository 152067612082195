import { AppTheme } from '../../types/theme';
import { css } from 'styled-components';

export default function (theme: AppTheme) {
    const sassVars = theme.variables.sass as any;
    return css`
        // либо текст ошибки, либо доп. инфа к полю
        .help-block {
            margin: ${sassVars.inputErrorHelpBlockOffsetTop} 0 0;
            color: ${sassVars.inputHelpBlockColor};
            font-family: ${sassVars.inputHelpBlockFontFamily};
            font-weight: ${sassVars.inputHelpBlockFontWeight};
            font-size: ${sassVars.inputHelpBlockFontSizeSm};
            line-height: ${sassVars.inputHelpBlockLineHeight};
        }

        // с приходом редизайна отступы под текст ошибки учтены в margin-ах полей
        .formio-errors .help-block {
            position: absolute;
            font-size: ${sassVars.inputHelpBlockFontSize};
            text-transform: ${sassVars.inputHelpBlockTextTransform};
        }

        .formio-component-editgrid .formio-errors .help-block {
            position: static;
        }
    `;
}
