import { AppTheme } from '../../types/theme';
import { createGlobalStyle, css } from 'styled-components';
import { withTheme } from '@material-ui/core';

function createFormioModalCss(theme: AppTheme) {
    const sassVars = theme.variables.sass as any;
    return css`
        .formio-dialog {
            z-index: ${theme.zIndex.modal};
            position: fixed;
            inset: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            &-overlay {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: ${sassVars.formioModalOverlayBackground};
            }

            &-content {
                z-index: ${theme.zIndex.modal};
                border-radius: ${theme.shape.borderRadius}px;
                background-color: ${theme.palette.background.paper};
                box-shadow: ${theme.shadows[24]};

                .MuiButton-root {
                    margin: 0 ${theme.spacing(1)}px;
                }

                .MuiDialogTitle-root {
                    padding: ${theme.spacing(4)}px ${theme.spacing(6)}px;
                }

                .MuiDialogActions-root {
                    padding: ${theme.spacing(2)}px;
                }
            }
        }
    `;
}

const GlobalStyledFormioModal = createGlobalStyle<{ theme: AppTheme }>`
    ${({ theme }) => createFormioModalCss(theme)}
`;

export default withTheme(GlobalStyledFormioModal);
