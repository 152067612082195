import { AppTheme } from '../../types/theme';
import { css } from 'styled-components';

export default function (theme: AppTheme) {
    const sassVars = theme.variables.sass as any;
    return css`
        width: ${sassVars.viewOnlyDlWidth};
        float: ${sassVars.viewOnlyDlFloat};
        margin-bottom: ${sassVars.viewOnlyComponentFieldsetMarginBottom};

        > dt {
            font-size: ${sassVars.viewOnlyFieldLabelFontSize};
            font-weight: ${sassVars.viewOnlyFieldLabelFontWeight};
            line-height: ${sassVars.viewOnlyFieldLabelLineHeight};
            color: ${sassVars.viewOnlyFieldLabelColor};
            margin-bottom: ${sassVars.viewOnlyFieldLabelMarginBottom};
        }

        > dd {
            font-size: ${sassVars.viewOnlyFieldValueFontSize};
            line-height: ${sassVars.viewOnlyFieldValueLineHeight};
            font-weight: ${sassVars.viewOnlyFieldValueFontWeight};
            color: ${sassVars.viewOnlyFieldValueColor};
        }
    `;
}
