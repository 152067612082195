import { Submission, WebForm } from '@platform/formio/WebForm';

export class FormioFormApi {
    form: WebForm;
    readOnly: boolean;

    constructor(form: WebForm, readOnly: boolean = false) {
        this.form = form;
        this.readOnly = readOnly;
    }

    setSubmission(s: Submission): Promise<any> {
        return this.form.setSubmission(s);
    }

    getSubmission(): Submission {
        return Object.assign({}, this.form.submission);
    }

    getExpertiseInfo(): object | undefined {
        const expertiseForm = this.form.components.find((_) => _.component.type === 'expertiseForm');
        let getExpertiseInfo = expertiseForm?.getExpertiseInfo;
        getExpertiseInfo = getExpertiseInfo?.bind(expertiseForm);
        if (typeof getExpertiseInfo === 'function') {
            return getExpertiseInfo();
        } else {
            return undefined;
        }
    }

    validate(): boolean {
        this.form.setPristine(false);
        return this.form.checkData(this.getSubmission().data, {}, null);
    }

    submit(validation: boolean): Promise<void> {
        const options = validation ? { state: 'submitted' } : { state: 'draft' };
        return this.form.submit(false, options);

    }

    destroy(): void {
        this.form.destroy();
    }
}
