import { AppTheme } from '../../types/theme';
import { css } from 'styled-components';

export default function (theme: AppTheme) {
    const sassVars = theme.variables.sass as any;
    return css`
        .btn {
            transition: 400ms all;
            border-radius: ${sassVars.btnBorderRadius};
            border: none;
            padding: ${sassVars.btnPaddingVertical} ${sassVars.btnPaddingHorizontal};
            outline: none;
            font-family: ${sassVars.btnFontFamily};
            font-weight: ${sassVars.btnFontWeight};
            font-size: ${sassVars.btnFontSize};
            line-height: ${sassVars.btnLineHeight};
            text-transform: ${sassVars.btnTextTransform};
            box-shadow: ${sassVars.btnBoxShadow};
            letter-spacing: ${sassVars.btnLetterSpacing};

            &:focus,
            &:active {
                box-shadow: ${sassVars.btnFocusBoxShadow};
            }

            &.disabled,
            &[disabled] {
                opacity: 1;
            }
        }

        .btn-sm {
            @include button-small;
        }

        .btn-lg {
            @include button-large;
        }

        // ICS-211 reopen fix (замечание 2):
        // при dev-сборке никаких проблем не было, но почему-то эти 3 строчки не попадали в production build
        // когда разделила btn-success и btn-primary проблема ушла, почему - не знаю🤡🤡🤡🤡
        // с остальными стилями (hover, focus и т.д.) описанными ниже проблем нет
        .btn-success {
            color: ${theme.palette.primary.contrastText};
            background: ${theme.palette.primary.main};
            border-color: ${theme.palette.primary.main};
        }

        .btn-primary {
            color: ${theme.palette.primary.contrastText};
            background: ${theme.palette.primary.main};
            border-color: ${theme.palette.primary.main};
        }

        .btn-primary,
        .btn-success {
            &:hover,
            &:hover:focus,
            &:active,
            &:active:focus {
                background: ${theme.variables.palette.accentBackgroundGradient};
                color: ${theme.variables.palette.accentContrastBackgroundGradient ||
                theme.variables.palette.accentContrast};
                border-color: transparent;
            }

            &:focus {
                color: ${theme.palette.primary.contrastText};
                background: ${theme.palette.primary.main};
                border-color: ${theme.palette.primary.main};
            }

            &.disabled,
            &[disabled] {
                background: ${sassVars.btnDisabledBackgroundColor};
                color: ${sassVars.btnDisabledColor};
                border-color: ${sassVars.btnDisabledBorderColor};

                &:hover {
                    background: ${sassVars.btnDisabledBackgroundColor};
                    color: ${sassVars.btnDisabledColor};
                    border-color: ${sassVars.btnDisabledBorderColor};
                }
            }
        }

        .btn-group {
            &.open > .dropdown-toggle {
                &.btn-primary,
                &.btn-success {
                    background: ${theme.variables.palette.accentBackgroundGradient};
                    color: ${theme.variables.palette.accentContrastBackgroundGradient ||
                    theme.variables.palette.accentContrast};
                    border-color: transparent;
                    box-shadow: none;
                }
            }
        }

        .btn-gray {
            background: #9a9a9a;
            color: #fff;
            border-color: #9a9a9a;

            &:hover {
                background: #848484;
                color: #fff;
                border-color: #9a9a9a;
            }

            &:focus,
            &:active:focus {
                background: #9a9a9a;
                color: #fff;
                border-color: #9a9a9a;
            }
        }

        // стили для btn-info не изобретены, поэтому применим default
        .btn-default,
        .btn-info {
            background: transparent;
            color: ${theme.palette.primary.main};
            border-color: transparent;

            &:hover,
            &:focus,
            &:active,
            &:active:focus {
                background: ${theme.variables.palette.accentBackgroundGradient};
                color: ${theme.variables.palette.accentContrastBackgroundGradient ||
                theme.variables.palette.accentContrast};
                border-color: transparent;
            }

            &.disabled,
            &[disabled] {
                color: ${sassVars.btnDisabledColor};
                border-color: ${sassVars.btnDisabledBorderColor};

                &:hover {
                    color: ${sassVars.btnDisabledColor};
                    border-color: ${sassVars.btnDisabledBorderColor};
                }
            }
        }
    `;
}
