import Utils from '@platform/formio/utils';
import { ComponentWithValidationMessage } from '../components/formioValidation';
import { defaultFormsLocale } from '../constants/intl';
import { FormApi } from '../api/FormApi';

type FlkValidateReadOnlyPageProps = {
    formApi?: FormApi;
    errors?: Record<string, any>;
};

type SearchQuery = {
    key: string;
    name: string;
    id: string;
    message: string;
};

export function flkValidateComponentIfVisible(
    component: any,
    data: any,
    errors?: Record<string, any>,
): ComponentWithValidationMessage[] {
    const isComponentVisible = component.checkConditions();

    return !isComponentVisible ? [] : flkValidateComponent(component, data, errors);
}

function flkValidateComponent(
    component: any,
    data: any,
    errors?: Record<string, any>,
): ComponentWithValidationMessage[] {
    const result: ComponentWithValidationMessage[] = [];

    flkValidate(errors, component, result);

    if (typeof component.getComponents === 'function') {
        return component
            .getComponents()
            .reduce((arr: any[], comp: any) => arr.concat(flkValidateComponentIfVisible(comp, data, errors)), result);
    } else {
        return result;
    }
}

export const flkValidateReadonlyPage = (props: FlkValidateReadOnlyPageProps): void => {
    const { formApi, errors } = props;

    if (formApi) {
        const formData = formApi.getSubmission();

        flkValidateComponentIfVisible(formApi.form(), formData[defaultFormsLocale].data, errors);
    }
};

function flkValidate(errors: any, component: any, result: ComponentWithValidationMessage[]) {
    flkValidateErrors(errors, component, result);
}

export function flkValidateErrors(errors: any, component: any, result: ComponentWithValidationMessage[]) {
    if (!errors) {
        return;
    }

    const errorMessage = errors[component.component.key];

    if (!errorMessage) {
        return;
    }

    // для обычных полей
    // errorMessage = string
    if (errorMessage && typeof errorMessage !== 'object') {
        const errorObj = {
            component: component.component,
            message: errorMessage,
        };
        component.error = errorObj;
        result.push(errorObj);
        component.addFlkMessage(errorMessage, component.options.name);
    }

    // для полей в эдитгриде и похожих структурах
    // errorMessage = [{ textfield: "message", }, {}]
    if (errorMessage && typeof errorMessage === 'object' && typeof component.getComponents === 'function') {
        const queries = generateQueries(errorMessage, component);
        queries.forEach((query) => {
            const comp = findComponent(component.components, { key: query.key, 'options.name': query.name });

            const errorObj = {
                component: comp.component,
                message: query.message,
            };
            comp.error = errorObj;
            result.push(errorObj);
            comp.addFlkMessage(query.message, query.id);
        });
    }

    // для полей с multiple value
    // errorMessage = ["message1", "message2"]
    if (errorMessage && Array.isArray(errorMessage) && typeof component.getComponents !== 'function') {
        errorMessage.forEach((error, index) => {
            if (error) {
                const errorObj = {
                    component: component.component,
                    message: error,
                };
                component.error = errorObj;
                result.push(errorObj);
                component.addFlkMessage(error, `${component.options.name}[${index}]`);
            }
        });
    }
}

// метод для генерации запроса поиска компонента
// из сабмишена генерируем name, id, key, message компонента
// key и name нужны для различия компонентов в разных строках editGrid и тд
// id для различия в texfield с multiple values
function generateQueries(errorsSubmission: any, component: any, name?: string) {
    let componentName = name || `data[${component.component.key}]`; // имя из options.name для поиска компонента
    let id = componentName; // id для поиска в DOM, нужно для обнаружения элементов при multiple values
    let lastKey = component.component.key;
    let errorMessage = '';
    const queries: Array<SearchQuery> = [];

    errorsSubmission.forEach((error: any, index: number) => {
        if (Object.keys(error).length && error) {
            Object.keys(error).forEach((key) => {
                componentName += `[${index}][${key}]`;
                id = componentName;
                lastKey = key;
                errorMessage = error[lastKey];

                if (Array.isArray(errorMessage)) {
                    errorMessage.forEach((value, index) => {
                        if (!value) {
                            return;
                        }

                        id += `[${index}]`;
                        errorMessage = value;

                        queries.push({
                            key: lastKey,
                            name: componentName,
                            id,
                            message: errorMessage,
                        });
                        id = componentName;
                    });
                    componentName = `data[${component.component.key}]`;
                    id = componentName;
                    return;
                }

                queries.push({
                    key: lastKey,
                    name: componentName,
                    id,
                    message: errorMessage,
                });

                componentName = `data[${component.component.key}]`;
                id = componentName;
            });
        }
    });
    return queries;
}

function findComponent(components: any, query: object) {
    return Utils.searchComponents(components, query)[0];
}
