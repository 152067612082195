import { AppTheme } from '../../types/theme';
import formLabel from '../mixins/formLabel';
import { css } from 'styled-components';

export default function (theme: AppTheme) {
    const sassVars = theme.variables.sass as any;
    return css`
        .panel .formio-component-fieldset,
        .formio-component-fieldset .formio-component-fieldset {
            legend {
                ${formLabel(theme)};
                border-bottom: none;
            }

            .card-body {
                border: ${sassVars.formioFieldsetBorder};
                padding: ${sassVars.formioFieldsetBodyPadding};

                > .form-group {
                    margin-bottom: ${sassVars.formioFieldsetFormGroupMarginBottom};
                }
            }
        }
    `;
}
