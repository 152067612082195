import { AppTheme } from '../../types/theme';
import { css } from 'styled-components';
import textEllipsis from '../mixins/textEllipsis';

export default function (theme: AppTheme) {
    return css`
        &.expertise-task-report-edit-form {
            height: 100%;

            > div {
                height: 100%;

                > div.panel {
                    height: 100%;
                    display: flex;
                    overflow: hidden;
                    flex-direction: column;
                    background-color: transparent;

                    > .card-header {
                        height: 49px;
                        overflow: hidden;

                        > .card-title {
                            height: 100%;
                            flex-wrap: nowrap;
                            overflow: hidden;
                            align-items: initial;
                            min-height: 48px;

                            &:before {
                                min-width: 83px !important;
                                width: 83px !important;
                            }

                            > .panel-title-text {
                                ${() => textEllipsis()}
                                display: block;
                                font-size: 15px;
                                line-height: 20px;
                                margin-right: 180px;
                                padding: 14px 0 13px;
                            }

                            > .score {
                                top: 3px;
                                right: 83px;
                            }

                            > .panel-index {
                                position: absolute;
                                left: 0;
                                font-size: 15px;
                                line-height: 20px;
                                padding: 14px 8px 13px;
                                letter-spacing: 0.3px;
                            }
                        }
                    }

                    > .card-body {
                        flex: 1;
                        overflow: auto;

                        .panel {
                            background-color: transparent;
                        }
                    }

                    > .panel-heading {
                        height: auto !important;
                        > .panel-title {
                            &:before {
                                content: '';
                                width: 0;
                                min-width: 0;
                            }

                            .panel-title-text {
                                margin-right: 154px;
                                padding: 15px 0;
                                overflow: visible !important;
                                white-space: normal !important;
                            }

                            .hide-report-title {
                                overflow: hidden !important;
                                white-space: nowrap !important;
                            }
                        }
                    }
                }
                > div > .panel > .panel-body > *:not(.panel):not(fieldset) {
                    background-color: transparent;
                }
            }
        }
    `;
}
