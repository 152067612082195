import { AppTheme } from '../../types/theme';
import { css } from 'styled-components';

export default function (theme: AppTheme) {
    const sassVars = theme.variables.sass as any;
    return css`
        font-size: ${sassVars.btnFontSizeXs};
        padding: ${sassVars.btnPaddingVerticalXs} ${sassVars.btnPaddingHorizontalXs};
    `;
}
