import { AppTheme } from './types/theme';
import { css } from 'styled-components';
import CompleteIcon from 'complete-icon.svg';

export default function (theme: AppTheme) {
    const checkRadioBox = theme.variables.formio.checkRadioBox;
    const palette = theme.variables.palette;
    return css`
        .radio .control-label,
        .checkbox .control-label {
            padding-left: ${checkRadioBox.checkRadioBoxPadding}px;
        }

        .checkbox input[type='checkbox'],
        .checkbox-inline input[type='checkbox'],
        .radio input[type='radio'],
        .radio-inline input[type='radio'] {
            position: absolute;
            clip: rect(0 0 0 0);
            width: 1px;
            height: 1px;
            margin: -1px;

            & ~ span {
                font-size: ${checkRadioBox.checkRadioBoxFontSize}px;
                color: ${palette.textMain || palette.main};
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    width: ${checkRadioBox.checkRadioBoxIconSize.width}px;
                    height: ${checkRadioBox.checkRadioBoxIconSize.height}px;
                    left: ${checkRadioBox.checkRadioBoxIconPosition.left}px;
                    top: ${checkRadioBox.checkRadioBoxIconPosition.top};
                }

                &:hover {
                    color: ${palette.accentContrast};
                    &:before {
                        border-color: ${palette.main};
                    }
                }
            }

            &:focus ~ span {
                color: ${palette.accentContrast};
                &:before {
                    border-color: ${palette.main};
                }
            }
        }

        .radio input[type='radio'],
        .radio-inline input[type='radio'] {
            & ~ span {
                &:before {
                    border: 1px solid ${palette.mainMiddleLight};
                    border-radius: ${checkRadioBox.radioBorderRadius};
                }
            }
            &:checked ~ span {
                &:after {
                    content: '';
                    position: absolute;
                    width: ${checkRadioBox.checkedRadioIconSize.width}px;
                    height: ${checkRadioBox.checkedRadioIconSize.height}px;
                    left: ${checkRadioBox.checkedRadioIconPosition.left}px;
                    top: ${checkRadioBox.checkedRadioIconPosition.top};
                    border-radius: ${checkRadioBox.radioBorderRadius};
                    background-color: ${palette.main};
                }
            }
        }

        .checkbox input[type='checkbox'],
        .checkbox-inline input[type='checkbox'] {
            & ~ span {
                &:before {
                    border: 1px solid ${palette.mainMiddleLight};
                    border-radius: ${checkRadioBox.checkboxBorderRadius}px;
                }
            }
            &:checked ~ span {
                &:before {
                    background-color: ${palette.main};
                    background-image: url(${CompleteIcon});
                    background-size: cover;
                    background-repeat: no-repeat;
                    border: none;
                }
            }
        }
    `;
}
